import { Injectable } from '@angular/core';
import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';
import { Hotkey, HotkeysService } from '@ngneat/hotkeys';

import { Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { defaults, find, isEmpty, remove } from 'lodash';

import { CommonContextMenuFactory } from '@CaseOne/Common/context-menu/context-menu.service/common-context-menu.factory';
import { CommonPopupService } from '@CaseOne/Common/popup/services/popup.service';
import { CommonUserDataService } from '@CaseOne/Common/user/user_data.service';
import { TCommonPermissionsHasPermissionsFnPermissions } from '@CaseOne/Common/permissions/interfaces/permissions.interfaces';
import { CommonPermissionsService } from '@CaseOne/Common/permissions/services/permissions.service';
import { CommonNotificationService } from '@CaseOne/Common/notification/notification.service';
import { getClosest } from '@CaseOne/Common/utilities/html';
import { CommonDialogPopupService } from '@CaseOne/Common/dialog-factory/services/dialog-popup.service';

export interface ICommonHotkeyOptions extends Hotkey {
	callback?: (event: KeyboardEvent | Hotkey) => any;
}

export interface ICommonHotkeyByLocaleConfig {
	name: string;
	keys: Record<'en' | 'fr' | 'pt' | 'ru', string>;
	permissions?: TCommonPermissionsHasPermissionsFnPermissions;
	callback: (event?: KeyboardEvent) => any;
	inCondition?: () => boolean;
	isEnabled?: () => boolean;
}

@Injectable()
export class CommonHotkeyService extends CommonBaseService {
	private hotkeysService = this.injector.get(HotkeysService);
	private commonContextMenuFactory = this.injector.get(CommonContextMenuFactory);
	private commonPopupService = this.injector.get(CommonPopupService);
	private commonDialogPopupService = this.injector.get(CommonDialogPopupService);
	private $injector = this.injector.get('$injector');
	private commonUserDataService = this.injector.get(CommonUserDataService);
	private commonPermissionsService = this.injector.get(CommonPermissionsService);
	private commonNotificationService = this.injector.get(CommonNotificationService);

	private readonly defaultHotkeyConfig: Partial<ICommonHotkeyByLocaleConfig> = {
		inCondition: () => {
			const popupQueueService = this.$injector.get('PopupQueueService');

			return !popupQueueService.isOpen()
				&& !this.commonPopupService.isOpen()
				&& !this.commonDialogPopupService.isOpen()
				&& !this.commonContextMenuFactory.isOpenContextMenu;
		},
		isEnabled: () => {
			return !this.commonUserDataService.getData().IsClient;
		}
	};

	private hotkeysByLocaleConfigs: ICommonHotkeyByLocaleConfig[] = [];

	addHotkey(hotkey: ICommonHotkeyOptions): Observable<KeyboardEvent> {
		const hotkey$ = this.hotkeysService.addShortcut(hotkey);

		hotkey$
			.pipe(
				filter((event: KeyboardEvent) => !this.isEventOnEditor(event)),
			)
			.subscribe((event) => {
				hotkey.callback?.(event);
			});

		return hotkey$;
	}

	addSequenceHotkey(hotkey: ICommonHotkeyOptions): Observable<Hotkey> {
		const hotkey$ = this.hotkeysService.addSequenceShortcut(hotkey);

		hotkey$.subscribe((event) => {
			hotkey.callback?.(event);
		});

		return hotkey$;
	}

	removeHotkey(keys: string | string[]) {
		this.hotkeysService.removeShortcuts(keys);
	}

	addHotkeyByLocale(config: ICommonHotkeyByLocaleConfig): Observable<KeyboardEvent | null> {
		this.hotkeysByLocaleConfigs.push(config);

		const extendedConfig: ICommonHotkeyByLocaleConfig = defaults(config, this.defaultHotkeyConfig);
		const key = extendedConfig.keys[this.commonLocaleService.locale];

		let hotkey$ = of(null);

		if (key && extendedConfig.isEnabled()) {
			hotkey$ = this.hotkeysService.addShortcut({
				keys: key,
				preventDefault: false,
			}).pipe(
				filter((event: KeyboardEvent) => extendedConfig.inCondition()),
				filter((event: KeyboardEvent) => !this.isEventOnEditor(event)),
				tap((event: KeyboardEvent) => {
					const noPermissionsCondition = extendedConfig.permissions && !this.commonPermissionsService.hasPermissions(extendedConfig.permissions)
						|| !this.commonPermissionsService.hasPermissions(['FullUserInterface:Read']);

					if (noPermissionsCondition) {
						this.commonNotificationService.show(
							this.instant('common.hotkeys.noty.no_grants.title'),
							this.instant('common.hotkeys.noty.no_grants.text'),
							'error',
						);
					} else {
						extendedConfig.callback?.(event);
					}
				}),
			);

			hotkey$.subscribe();
		}

		return hotkey$;
	}

	removeHotkeyByLocale(config: ICommonHotkeyByLocaleConfig) {
		remove(this.hotkeysByLocaleConfigs, {name: config.name});
		this.removeHotkey(config.keys[this.commonLocaleService.locale]);
	}

	getLocaleHotkeyKey(configName: string): string {
		const config = find(this.hotkeysByLocaleConfigs, {name: configName});

		return config ? config.keys[this.commonLocaleService.locale] : null;
	}

	replaceHotkey (hotkey: ICommonHotkeyOptions): ICommonHotkeyOptions {
		if (!isEmpty(hotkey)) {
			const hotkeys = this.hotkeysService.getHotkeys();
			const previousHotkey = find(hotkeys, {keys: hotkey.keys});
			if (previousHotkey) {
				this.removeHotkey(previousHotkey.keys);
			}

			this.addHotkey(hotkey);

			return previousHotkey || null;
		}

		return null;
	}

	private isEventOnEditor(event: KeyboardEvent): boolean {

		// TODO:[angular] remove 'common-html-editor-component-for-angular-js' condition after upgrade to angular
		const foundHtmlEditor = getClosest(event.target as HTMLElement, 'common-html-editor-component-for-angular-js')
			|| getClosest(event.target as HTMLElement, 'common-html-editor');

		return Boolean(foundHtmlEditor);
	}
}
