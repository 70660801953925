import { CommonPopupService } from '@CaseOne/Common/popup/services/popup.service';
import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { TransitionService } from '@uirouter/core';
import { CommonPopupOptions } from '@CaseOne/Common/popup/popup-options';
import { CommonPopup } from '@CaseOne/Common/popup/popup';


// Only for CommonDialogFactoryService
@Injectable({
	providedIn: 'root',
})
export class CommonDialogPopupService extends CommonPopupService {
	constructor (
		overlay: Overlay,
		injector: Injector,
		transitionService: TransitionService,
	) {
		super(overlay, injector, transitionService);

		this.oldPopupsIntegrationMode = false;
	}

	open (options: CommonPopupOptions): CommonPopup {
		options.isOverlapped = true;
		return super.open(options);
	}

	protected createBackgroundOverlay() {
		// nothing
	}

	protected removeBackground () {
		// nothing
	}
}
